.profile-widget {
  margin-top: 35px;
  .profile-widget-picture {
    @include shadow;
    width: 100px;
    margin-top: -35px;
    display:block;
    z-index: 1;
  }
  
  .profile-widget-header {
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
  }
  .profile-widget-items {
    display: flex;
    position: relative;
    margin-top: 10px;
    &:after {
      content: ' ';
      position: absolute;
      bottom: 0;
      left: 0px;
      right: 0;
      height: 1px;
      background-color: #f2f2f2;
    }
    .profile-widget-item {
      flex: 1;
      text-align: center;
      border-top: 1px solid #f2f2f2;
      border-right: 1px solid #f2f2f2;
      padding: 15px 0;
      &:last-child {
        border-right: none;
      }
      .profile-widget-item-label {
        font-weight: 600;
        font-size: 12px;
        letter-spacing: .5px;
        color: color(fontdark);
      }
      .profile-widget-item-value {
        color: #000;
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
  .profile-widget-description {
    padding: 20px;
    line-height: 26px;
    .profile-widget-name {
      font-size: 16px;
      margin-bottom: 10px;
      font-weight: 600;
    }
  }
}
.full-background-color{
	background: linear-gradient(180deg, color(warning) 0%, color(warning2) 100%);
	height: 120px;
    border-radius: 5px 5px 0px 0px;
    padding-top: 10px;
	.profile-widget-image {
    	@include shadow;
    	width: 100px;
    	display: block;
    	z-index: 1;
  	}
 }
