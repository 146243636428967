.settingSidebar {
	background: #fff;
	position: fixed;
	height: 100%;
	width: 280px;
	top: 70px;
	right: -280px;
	z-index: 100;
	transition: 0.3s ease-in;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);
	.settingPanelToggle {
		background: #6777ef;
		padding: 10px 15px;
		color: #fff;
		position: absolute;
		top: 40%;
		left: -40px;
		width: 40px;
		border-radius: 8px 0 0 8px;
	}
	&.showSettingPanel {
		right: 0;
	}
	.settingSidebar-body {
		position: relative;
		height: 100%;
	}
	.settingSidebar-tab {
		display: flex;
		.nav-item {
			width: 33.33%;
			text-align: center;
			.nav-link {
				padding: 15px 12px;
				color: #6a7a8c;
				border-bottom: 3px solid transparent;
				&.active {
					border-bottom: 3px solid #2962ff;
					color: #2962ff;
				}
				&:hover {
					border-bottom: 3px solid #2962ff;
					color: #2962ff;
				}
			}
		}
	}
	ul.choose-theme li {
	display: inline-block;
	&:hover {
		cursor: pointer;
	}
	
}

ul.choose-theme li div {
	border-radius: 4px;
    display: inline-block;
    vertical-align: middle;
    height: 25px;
    width: 25px;
    overflow: hidden;
    position: relative;
    //border: 7px solid rgba(0, 0, 0, 0.3);
    margin:2px;
}

ul.choose-theme li div.blue {
	background: #7f8bb8;
	-webkit-box-shadow: 4px 3px 6px 0 rgba(0,0,0,0.2);
    box-shadow: 4px 3px 6px 0 rgba(0,0,0,0.2);
}

ul.choose-theme li div.purple {
	background: #e0376f;
	-webkit-box-shadow: 4px 3px 6px 0 rgba(0,0,0,0.2);
    box-shadow: 4px 3px 6px 0 rgba(0,0,0,0.2);
}

ul.choose-theme li div.allports {
	background: #0e6174;
	-webkit-box-shadow: 4px 3px 6px 0 rgba(0,0,0,0.2);
    box-shadow: 4px 3px 6px 0 rgba(0,0,0,0.2);
}

ul.choose-theme li div.barossa {
	background: #b5508d;
	-webkit-box-shadow: 4px 3px 6px 0 rgba(0,0,0,0.2);
    box-shadow: 4px 3px 6px 0 rgba(0,0,0,0.2);
}

ul.choose-theme li div.fancy {
	background: #6c6580;
	-webkit-box-shadow: 4px 3px 6px 0 rgba(0,0,0,0.2);
    box-shadow: 4px 3px 6px 0 rgba(0,0,0,0.2);
}

ul.choose-theme li div.white {
	background: #ece8e8;
	-webkit-box-shadow: 4px 3px 6px 0 rgba(0,0,0,0.2);
    box-shadow: 4px 3px 6px 0 rgba(0,0,0,0.2);
}
ul.choose-theme li div.coral {
	background: #343a40;
	-webkit-box-shadow: 4px 3px 6px 0 rgba(0,0,0,0.2);
    box-shadow: 4px 3px 6px 0 rgba(0,0,0,0.2);
}



ul.choose-theme li {
	display: inline-block;
	&:hover {
		cursor: pointer;
	}
	
}

.setting-panel-header{
	display: block;
    padding: 15px 20px;
    color: #212529;
    font-size: 15px;
	border: 1px solid #eae9e9;
	background: #e9ecef;
}
.disk-server-setting{
	.progress{
		height: 8px;
	}
	p{
		font-weight: bold;
		margin: 0;
		border-bottom: 1px solid #eee;
		font-size: 14px;
		text-align: left;
		padding-bottom: 5px;
	}
}
.rt-sidebar-last-ele {
	margin-bottom: 70px !important;
}
}

ul.sidebar-color li {
	display: inline-block;
	&:hover {
		cursor: pointer;
	}
	
}

ul.sidebar-color li div {
	//border-radius: 15px;
    display: inline-block;
    vertical-align: middle;
    height: 50px;
    width: 25px;
    overflow: hidden;
    position: relative;
    //border: 7px solid rgba(0, 0, 0, 0.3);
    margin:2px;
}

ul.sidebar-color li div.blue {
	background-image: -moz-linear-gradient(
		360deg,#384161 30%,#7f8bb8 100%
	);
	background-image: -webkit-linear-gradient(
		360deg,#384161 30%,#7f8bb8 100%
	);
	background-image: -ms-linear-gradient(
		360deg,#384161 30%,#7f8bb8 100%
	);
	-webkit-box-shadow: 4px 3px 6px 0 rgba(0,0,0,0.2);
    box-shadow: 4px 3px 6px 0 rgba(0,0,0,0.2);
}

ul.sidebar-color li div.purple {
	background-image: -moz-linear-gradient(
		360deg, rgb(52, 58, 93) 0%,rgb(224, 55, 111) 100%
	);
	background-image: -webkit-linear-gradient(
		360deg, rgb(52, 58, 93) 0%,rgb(224, 55, 111) 100%
	);
	background-image: -ms-linear-gradient(
		360deg, rgb(52, 58, 93) 0%,rgb(224, 55, 111) 100%
	);
	-webkit-box-shadow: 4px 3px 6px 0 rgba(0,0,0,0.2);
    box-shadow: 4px 3px 6px 0 rgba(0,0,0,0.2);
}

ul.sidebar-color li div.fancy {
	background-image: -moz-linear-gradient(
		360deg, rgb(54, 45, 77) 30%,rgb(108, 101, 128) 100%
	);
	background-image: -webkit-linear-gradient(
		360deg, rgb(54, 45, 77) 30%,rgb(108, 101, 128) 100%
	);
	background-image: -ms-linear-gradient(
		360deg, rgb(54, 45, 77) 30%,rgb(108, 101, 128) 100%
	);
	-webkit-box-shadow: 4px 3px 6px 0 rgba(0,0,0,0.2);
    box-shadow: 4px 3px 6px 0 rgba(0,0,0,0.2);
}

ul.sidebar-color li div.coral {
	background: #1a202e;
	-webkit-box-shadow: 4px 3px 6px 0 rgba(0,0,0,0.2);
    box-shadow: 4px 3px 6px 0 rgba(0,0,0,0.2);
}

ul.sidebar-color li div.white {
	background: #ece8e8;
	-webkit-box-shadow: 4px 3px 6px 0 rgba(0,0,0,0.2);
    box-shadow: 4px 3px 6px 0 rgba(0,0,0,0.2);
}
ul.sidebar-color li div.allports {
	background-image: -moz-linear-gradient(
		360deg, rgb(7, 49, 58) 30%,rgb(14, 97, 116) 100%
	);
	background-image: -webkit-linear-gradient(
		360deg, rgb(7, 49, 58) 30%,rgb(14, 97, 116) 100%
	);
	background-image: -ms-linear-gradient(
		360deg, rgb(7, 49, 58) 30%,rgb(14, 97, 116) 100%
	);
	-webkit-box-shadow: 4px 3px 6px 0 rgba(0,0,0,0.2);
    box-shadow: 4px 3px 6px 0 rgba(0,0,0,0.2);
}

ul.sidebar-color li div.barossa {
	background-image: -moz-linear-gradient(
		360deg, #472539 30%, #b5508d 100%
	);
	background-image: -webkit-linear-gradient(
		360deg, #472539 30%, #b5508d 100%
	);
	background-image: -ms-linear-gradient(
		360deg, #472539 30%, #b5508d 100%
	);
	-webkit-box-shadow: 4px 3px 6px 0 rgba(0,0,0,0.2);
    box-shadow: 4px 3px 6px 0 rgba(0,0,0,0.2);
}

ul.choose-theme li.active div{
	border: 2px solid rgba(0, 0, 0, 0.3);
}

ul.sidebar-color li.active div{
	border: 2px solid rgba(0, 0, 0, 0.3);
} 

@media only screen and(max-width: 1024px) {
	.settingSidebar {
		display: none;
	}
}