.pricing {
  @include card-base(0px);
  border:0px;
  text-align: center;
  margin:15px;
  &.pricing-highlight {
    border: 3px solid rgba(0, 0, 0, 0.3);
  }
  .pricing-padding {
    padding: 40px;
  }
  .pricing-title {
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    background-color: color_lighten(light, 5%);
    color: color(primary);
    //display: inline-block;
    padding: 5px 15px;
  }
  .pricing-price {
    padding-bottom: 45px;
    .package-price {
      font-weight: 600;
      font-size: 45px;
    }
    .package-name {
      font-weight: 400;
      font-size: 30px;
    }
    
  }
  .pricing-details {
    text-align: left;
    display: inline-block;
    .pricing-item {
      display: flex;
      margin-bottom: 15px;
      .pricing-item-icon {
        width: 20px;
        height: 20px;
        line-height: 20px;
        border-radius: 50%;
        text-align: center;
        background-color: color(success);
        color: #fff;
        margin-right: 10px;
        i {
          font-size: 11px;
        }
      }
    }
  }
  .pricing-cta {
    margin-top: 20px;
    a {
      display: block;
      padding: 20px 40px;
      background-color: color_lighten(light, 5%);
      text-transform: uppercase;
      letter-spacing: 2.5px;
      font-size: 14px;
      font-weight: 700;
      text-decoration: none;
      .fas, .far, .fab, .fal, .ion {
        margin-left: 5px;
      }
      &:hover {
        background-color: color(light);
      }
    }
  }
}