/*
 *  Document   : _inbox.scss
 *  Author     : Snkthemes
 *  Description: This scss file for email page style classes
 */
.p-15{
    padding: 15px;
}
.p-10 {
    padding: 10px;
}
.b-b {
    border-bottom: 1px solid rgba(0,0,0,0.2);
}
.mail_listing{
    .mail-option {
        .btn-group {
            margin-bottom: 5px;
        }
    }
}
#mail-nav{
    .btn-compose{
        margin: 15px;
    	min-width: 87%;
    }
    a{
   		 text-decoration: none;
    }
    #mail-folders {
        list-style-type: none;
        padding: 0px 15px;
        i{
            padding-right: 5px;
            
        }
    >li{
        margin: 2px 0;
        a{
            &:hover{
                color: #9c27b0;
                background-color: #f2f3f5;
            }
        }
        &.active>a{
            color: #9c27b0;
            background-color: #E6E9ED;
            &:hover{
                background-color: #E6E9ED;
            }
        }
    }
}
    #mail-labels{
        float: left;
        width: 100%;
        i{
            padding-right: 5px;
            
        }
    }
    #online-offline {
        .material-icons{
            font-size: 8px;
            height: 8px;
            padding: 0px 5px 2px 0;
        }
    }
    #mail-labels,
    #online-offline{
        list-style-type: none;
        padding-left: 0px;
        li{
            a:hover{
                background-color: #e6e6e6;
            }
        }
    }
    li{
        a{
            color: #212529;
            padding: 5px 10px;
            display: block;
            border-radius: 4px;
            position: relative;
            -webkit-transition: all 0.2s ease-out;
            -moz-transition: all 0.2s ease-out;
            transition: all 0.2s ease-out;
        }
    }
}
.composeForm{
    padding: 25px;
}
.inbox-body{
    padding: 20px;
    
	font-size:15px;
}
.inbox-header{
	text-align: left;
}
.replyBox{
    border: 1px solid rgba(120, 130, 140, 0.13) ;
    padding: 20px 0px 0px 20px;
}
.inbox-center {
	tr td a{
		padding: 0px !important;
		text-align: left !important;
		font-weight:200;
	}
	tr td.tbl-checkbox{
	     padding-left: 15px !important;
	}
	tr.unread td a{
    	font-weight:600;
	}
	
	.table thead th {
	    vertical-align: middle;
	    padding: 15px !important;
	}
}
.email-btn-group{
    position: relative;
    display: -ms-inline-flexbox;
    display: -webkit-inline-box;
    display: inline-flex;
    vertical-align: middle;
    i{
    	font-size : 15px;
    }
}
.max-texts{
    padding: .75rem !important;
    a{
        color: #212529;
        padding: 10px 0px 10px 0px;
        text-decoration: none;
        &:hover {
        	color: #212529;
        }
    }
}

.email-box-title{
	font-size:17px !important;
    color: #9c27b0;
    font-weight:700;
}
.mail-sender{
	.media .media-title {
		margin-bottom:0px !important;
	}
}

.attachment-mail p{
    line-height: 0px !important;
}