$color-primary: #E91E63;
.activities {
  display: flex;
  flex-wrap: wrap;
  .activity {
    width: 100%;
    display: flex;
    position: relative;
    &:before {
      content: ' ';
      position: absolute;
      left: 25px;
      top: 3px;
      width: 3px;
      height: 100%;
      background-color: #e3e7eb;
    }
    &:last-child {
      &:before {
        display: none;
      }
    }
    .activity-icon {
      @include box();
      margin-right: 20px;
      border-radius: 50%;
      flex-shrink: 0;
      text-align: center;
      z-index: 1;
    }
    .activity-icon-linehight{
    	line-height: 40px!important;
    }
    .activity-detail {
      @include card_base;
      position: relative;
	  padding: 10px;
	  margin-bottom: 15px;
      &:before {
        content: '\f0d9';
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        font-size: 20px;
        position: absolute;
        left: -8px;
        color: #e3e7eb;
      }
      h4 {
        font-size: 18px;
        color: color(dark);
      }
      p {
        margin-bottom: 0;
      }
    }
  }
}
.timeline-img-border{
	border: 3px solid #e3e7eb;
}
.timeline-center{
	margin : 0px auto;
}
.timeline-activity-widget {
		.widget-activity-item1 {
		    border-left: 1px solid #e3e7eb;
		    padding-bottom: 1px;
		    padding-left: 15px;
		    position: relative;
		    &:before {
		    	border: 2px solid #C355F2;
			    background-color: #FFFFFF;
			    border-radius: 50%;
			    content: "";
			    height: 13px;
			    left: -6.5px;
			    position: absolute;
			    top: 0;
			    width: 13px;
			}
		}
	}
.timeline-activity {
		.activity-item1 {
		    border-left: 1px solid #e3e7eb;
		    padding-bottom: 1px;
		    padding-left: 15px;
		    position: relative;
		    &:before {
		    	//border:5px solid #C355F2;
			    background-color: #C355F2;
			    border-radius: 50%;
			    content: "";
			    height: 13px;
			    left: -6.5px;
			    position: absolute;
			    top: 0;
			    width: 13px;
			}
		}
		.activity-item2 {
		    border-left: 1px solid #e3e7eb;
		    padding-bottom: 1px;
		    padding-left: 15px;
		    position: relative;
		    &:before {
		    	//border:5px solid #1AF7C5;
			    background-color: #1AF7C5;
			    border-radius: 50%;
			    content: "";
			    height: 13px;
			    left: -6.5px;
			    position: absolute;
			    top: 0;
			    width: 13px;
			}
		}
		.activity-item3 {
		    border-left: 1px solid #e3e7eb;
		    padding-bottom: 1px;
		    padding-left: 15px;
		    position: relative;
		    &:before {
		    	//border:5px solid #F740E6;
			    background-color: #F740E6;
			    border-radius: 50%;
			    content: "";
			    height: 13px;
			    left: -6.5px;
			    position: absolute;
			    top: 0;
			    width: 13px;
			}
		}
		.activity-item4 {
		    border-left: 1px solid #e3e7eb;
		    padding-bottom: 1px;
		    padding-left: 15px;
		    position: relative;
		    &:before {
		    	//border:5px solid #F7DC1A;
			    background-color: #F7DC1A;
			    border-radius: 50%;
			    content: "";
			    height: 13px;
			    left: -6.5px;
			    position: absolute;
			    top: 0;
			    width: 13px;
			}
		}
	}