.author-box {
  .author-box-left {
    float: left;
    text-align: center;
    padding-left: 5px;
    .btn {
      padding: 5px 15px;
      font-size: 12px;
      border-radius: 30px;
    }
  }
  .author-box-center {
    text-align: center;
    padding-left: 5px;
    .btn {
      padding: 5px 15px;
      font-size: 12px;
      border-radius: 30px;
    }
  }
  .author-box-picture {
    width: 100px;
    @include shadow;
  }
  .author-box-details {
    margin-left: 135px;
  }
  .author-box-name {
    font-size: 18px;
    a {
      font-weight: 600;
    }
  }
  .author-box-job {
    font-weight: 600;
    letter-spacing: .5px;
    font-size: 12px;
    color: color(fontdark);
  }
  .author-box-description {
    line-height: 26px;
    margin-top: 15px;
  }
}

.box-center{
	margin: 0px auto;
	a{
		text-decoration: none;
    	color: #ffffff;
	}
	h5,h6{
		color: #ffffff;
	}
}
.background-image-body{
	background: linear-gradient(to bottom, rgba(0,0,0,0.7) 0%,rgba(0,0,0,0.7) 100%), url(../img/auth-background-image.jpg);
}
.btn-auth-color{
	background: linear-gradient(135deg, #ff2680 0%, #dd03b9 100%) !important;
	color: #630303!important;
}
.login-brand-color{
	color: #ffffff!important;
}
.login-invoice-color{
	color: #000000!important;
}
.logo-auth{
	margin-top:10px;
}
.logo-name-auth{
	vertical-align: middle;
    font-size: 30px;
}
@include media-breakpoint-down(xs) {
  .author-box {
    .author-box-left {
      float: none;
    }
    .author-box-center {
      float: none;
    }
    .author-box-details {
      margin-left: 0;
      margin-top: 15px;
      text-align: center;
    }
  }
}
