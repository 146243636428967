.statistic-details {
  display: flex;
  flex-wrap: wrap;
  .statistic-details-item {
    flex: 1;
    padding: 17px 10px;
    text-align: center;
    .detail-chart {
      margin-bottom: 10px;
      padding: 0 20px;
    }
    .detail-name {
      font-size: 12px;
      margin-top: 0px;
      color: color(fontdark);
      //letter-spacing: .3px;
    }
    .detail-value {
      font-size: 14px;
      font-weight: 700;
    }
    .card-widget-square{
  		width: 40px;
      height: 40px;
      line-height: 36px;
      font-size: 20px;
      margin: 10px 10px 10px 10px;
      border-radius: 6px;
      background: transparent;
      i{
        font-size: 18px;  
      }
  	}
  	.card-widget-color1{
  		border: 2px solid #4CAF50;
      color: #4CAF50;
  	}
  	.card-widget-color2{
  		border : 2px solid #29b3ba;
  		color : #29b3ba;
  	}
  	.card-widget-color3{
  		border : 2px solid #e9c65c;
  		color : #e9c65c;
  	}
  }
}

@include media-breakpoint-down(xs) {
  .statistic-details {
    flex-wrap: wrap;
    .statistic-details-item {
      flex: initial;
      width: 50%;
    }
  }
}